.item {
  height: 350px;
  width: 270px;
  position: relative;
  transition: transform 0.3s ease, z-index 0.3s ease, margin-top 0.3s ease;
}

.owl-stage .owl-item.center {
  position: relative;
  z-index: 100;
  margin-top: 10px;
  transform: scale(1.1);
}

.carousel-video .owl-item:nth-child(odd) {
  position: relative;
  transform: scale(1.1);
  z-index: 2;
  margin-top: 80px;
}

@media (max-width: 600px) {
  .item {
    height: 350px;
    width: 250px;
    margin: 0 auto;
    position: relative;
    transition: transform 0.3s ease, z-index 0.3s ease, margin-top 0.3s ease;
  }

  .owl-stage .owl-item.center {
    position: relative;
    z-index: 100;
    margin-top: 0px;
    transform: scale(1);
  }

  .carousel-video .owl-item:nth-child(odd) {
    position: relative;
    transform: scale(1);
    z-index: 2;
    margin-top: 20px;
  }
}
