body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.container {
  max-width: 1250px;
  margin-left: auto;
  margin-right: auto;
}

@font-face {
  font-family: "Mr Eaves XL Heavy";
  src: url("./fonts/MrEavesXLSanOT-Heavy.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Mr Eaves XL Reg";
  src: url("./fonts/MrEavesXLSanOT-Reg.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
